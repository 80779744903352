body,
ul {
  margin: 0;
  padding: 0;
  font-size: 18px;
}
h1,
h2,
h3,
p {
  margin: 2.5px;
  font-size: 18px;
}
h1, h2 {
  padding-top: 5px;
  font-size: 24px;
}

body {
  font-family: 'Helvetica', Arial, sans-serif;
  line-height: 1.6;
  background-color: #fefdfd;
  color: #333;
}

article {
  p {
    margin-top: 10px;
		// font-size: 18px;
  }
  ul {
    margin: 15px;
  }
  img {
    max-width: 500px;
  }
  header {
    background-color: unset;
    h1 {
      font-size: 24px;
      text-align: left;
    }
  }
}

.post-header {
  background-color: unset;
}

.post-meta {
  text-align: left;
}

.post-title {
  font-size: 24px;
  text-align: left;
}

.section-posts {
  ul li {
    list-style-type: none;
    text-decoration: none;
  }
}

.container {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-body {
  width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.container-footer {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .container,
  .container-body {
    width: auto;
    flex-direction: column;
    padding: 10px;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 10px;
  }

  article {
    img {
      max-width: 100%;
    }
  }
}

header {
  background-color: #fefdfd;
  text-align: center;
}

header a {
  text-decoration: none;
  color: inherit;
}

/* Navigation styling */
nav ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  text-decoration: none;
  color: #333;
}

footer {
  text-align: center;
  padding: 1rem 0;
  background-color: #fefdfd;
}

// Archive Page Styles
.archiveSpan {
  font-size: 15.75px;
  color: #8f8f8f;
  overflow: hidden;
  margin: 0;
}

.archiveTitle {
  color: #333;
}

.archiveLink:hover * {
  color: rgb(17, 17, 17);
}

.archive-post-list>ul {
  list-style: none;
}

.archive-post-list {
  ul {
      margin: 15px 0 15px 0;
    }

    a {
      text-decoration: none !important;
    }
}

// navBar
.hamburger-button {
  display: none;
}

.nav-links {
  display: flex;
}

@media (max-width: 768px) {
  .hamburger-button {
    cursor: pointer;
    border: 0;
    padding-top: 5px;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 28px;
    background: unset;
    right: 0px;
    transform: translateY(-50%);
    display: block;
    color: #1756a9;
  }

  .x-icon {
    display: none;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  nav ul li {
    text-align: center;
    margin: 10px 0;
  }
}
